







import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
import {getOrderSignInAsync} from '@/api/course/course-record'

@Component({})
export default class RecordList extends Vue {
  @Prop({default: () => ''}) private orderId!: string

  private records = new Array()

  @Watch('orderId', {immediate: true})
  public async getOrderId(vl: string, ov: string) {
    if (vl !== ov) {
      await this.getSignList(vl)
    }
  }

  private async getSignList(vl: any) {
    this.records = await getOrderSignInAsync(vl)
  }
}
