import request from '@/utils/request';

export function getOrderSignInAsync(orderId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    request({
      url: `/keep-fit/courseOrderRecord`,
      method: 'get',
      params: {
        orderId
      }
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}
