import request from '@/utils/request';

const url = '/keep-fit/course-coach/'

export default class CourseCoachApi {

  public async getCourseListAsync(courseId: string, state?: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${url}courses`,
        method: 'get',
        params: {
          courseId,
          state
        }
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }

  public async updateCourseCoachAsync(coachId: string, courseId: string, price: number, reason: string, state: number) {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${url}edit`,
        method: 'put',
        data: {
          coachId,
          courseId,
          price,
          reason,
          state
        }
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }
}
