





















































import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
import CourseCoachApi from '@/api/course/course-coach'
import '@/plugins/vxetable'

@Component({})
export default class CourseApplyList extends Vue {
  @Prop({default: () => false}) private visible!: boolean
  @Prop({default: () => ''}) private title!: string
  @Prop({default: () => ''}) private courseId!: string

  private tempCourseId = ''
  private tableData = new Array()
  private api = new CourseCoachApi()

  @Watch('courseId', {immediate: true})
  private getMemberId(nv: string, ov: string) {
    if (nv) {
      this.tempCourseId = nv
    }
  }

  private async mounted() {
    await this.getList()
  }

  private async getList() {
    if (this.tempCourseId) {
      this.tableData = await this.api.getCourseListAsync(this.tempCourseId)
    }
  }

  private async saveEvent(row: any) {
    const $table = this.$refs.dataTable as any;
    const errMap = await $table.validate().catch((errMap: any) => errMap);
    if (!errMap) {
      if (!row.state) {
        this.$message({message: '请选择审核状态', type: 'error'});
        return false;
      }

      if (!row.price) {
        this.$message({message: '请填写价格', type: 'error'});
        return false;
      }

      $table.clearActived().then(async () => {
        await this.api.updateCourseCoachAsync(row.coachId, row.courseId, row.price, row.reason, row.state)
        await this.getList()
      });
    }
  }

  private cancelRowEvent(row: any) {
    const $table = this.$refs.dataTable as any;
    $table.clearActived().then(() => {
      // 还原行数据
      $table.revertData(row);
    });
  }

  private updateHandle(row: any) {
    const $table = this.$refs.dataTable as any;
    $table.setActiveRow(row);
  }

  private handleClose() {
    this.$emit('handleClose')
  }

}
