









































































import {Component, Vue} from 'vue-property-decorator'
import CourseApi from '@/api/course/course';
import {CourseDto, GetListCourseDto} from '@/framework/models/Course';
import {PageResultDto} from '@/framework/models/ResultDto';

import CourseImg from '../../components/ShowImg.vue';
import CourseAdd from './components/CourseAdd.vue'
import CourseApplyList from './components/CourseApplyList.vue'
import CourseBuyPtList from './components/CourseBuyPtList.vue'

@Component({
  components: {
    CourseImg,
    CourseAdd,
    CourseApplyList,
    CourseBuyPtList
  }
})
export default class PersonalTraining extends Vue {
  private api = new CourseApi();
  private listQuery = new GetListCourseDto();
  private tableData: PageResultDto<CourseDto> = {
    items: new Array<CourseDto>(),
    totalCount: 0
  };

  private dialog = {
    title: '',
    visible: false
  };

  private dialogImg = false;
  private dialogList = false;
  private dialogApplyList = false
  private dialogTitle = ''
  private dialogId = '';

  private pagination = {
    currentPage: 1
  }

  private async activated() {
    this.listQuery.category = 2
    await this.getList();
  }

  private async currentChange(currentPage: number) {
    this.pagination.currentPage = currentPage
    this.listQuery.skipCount = (currentPage - 1) * this.listQuery.maxResultCount
    await this.getList()
  }

  private async getList() {
    this.tableData = await this.api.getListAsync(this.listQuery);
  }

  private handleCreate() {
    this.dialog.title = '新增';
    this.dialog.visible = true;
  }

  private async updateHandle(row: any) {
    this.dialog.visible = true;
    this.dialog.title = '编辑';
    this.dialogId = row.id;
  }

  private handlerDelete(row: any) {
    this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(async () => {
      // TODO 代码
      await this.api.DeleteAsync(row.id);
      await this.getList();
      this.$notify({
        message: '删除成功',
        type: 'success',
        title: ''
      });
    }).catch(() => {
    });
  }

  private handleCourseImg(row: any) {
    this.dialogImg = true;
    this.dialogId = row.id;
  }

  private handleByList(row: any) {
    this.dialogList = true;
    this.dialogId = row.id;
    this.dialogTitle = row.name
  }

  private async handleClose() {
    this.dialogImg = false;
    this.dialogList = false;
    this.dialog.visible = false;
    this.dialogApplyList = false;

    await this.getList()
  }

  private handleApplyList(row: any) {
    this.dialogApplyList = true;
    this.dialogId = row.id
    this.dialogTitle = row.name
  }
}
